<template>
  <div class="min-h-screen flex flex-col justify-center items-center">
    <div class="container mx-auto p-4">
      <ul class="grid grid-cols-3 gap-6">
        <li
          v-for="tier of tiers"
          :key="tier.name"
          class="border border-slate-100 shadow p-4"
        >
          <div>
            <span
              class="text-center text-xl font-semibold block mx-auto mb-4"
              >{{ tier.name }}</span
            >

            <ul class="px-4 list-disc">
              <li
                v-for="(detail, idx) of tier.details"
                :key="`${tier.name}-detail-${idx}`"
              >
                {{ detail.text }}
              </li>
            </ul>
            <button
              class="block mx-auto mt-4 bg-blue-600 text-blue-100 hover:bg-blue-700 px-4 py-3 rounded"
            >
              Select {{ tier.name }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
export default {
  setup(props) {
    const tiers = [
      {
        name: 'Tier 1',
        details: [
          {
            text: 'Included societies: ACAB, AZA',
          },
        ],
      },
      {
        name: 'Tier 2',
        details: [
          {
            text: 'All societies in Tier 1 + RABA, ZAZA',
          },
        ],
      },
      {
        name: 'Tier 3',
        details: [
          {
            text: 'All societies in Tier 1 and 2 + RABA, ZAZA',
          },
        ],
      },
    ];

    return {
      tiers,
    };
  },
};
</script>

<style lang="scss" scoped></style>
